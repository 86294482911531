import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import { getKy } from "../../helpers/ky";
import { UnlockPayload } from "../../types/UnlockPayload";
import { SubmarinedContent } from "../../types/SubmarinedContent";
import { setSubmarinedContentState, SubmarinedContentState } from "./submarinedContentSlice";
interface LockState {
  unlockPayloads: UnlockPayload[];
  totalLocks: number;
  shortId: string;
}
const initialState: LockState = {
  unlockPayloads: [],
  totalLocks: 0,
  shortId: "",
};

export const tryUnlock = createAsyncThunk("lock/tryUnlock", async (_, thunkAPI) => {
  const state = thunkAPI.getState() as {
    Lock: LockState;
  };

  const { unlockPayloads, totalLocks, shortId } = state.Lock;
  if (unlockPayloads.length !== totalLocks) {
    return;
  }
  const dispatch = thunkAPI.dispatch;
  dispatch(setSubmarinedContentState("loading"));
  const ky = getKy();
  const result: SubmarinedContent = await ky
    .post("/api/v1/unlock", { json: { unlockPayload: unlockPayloads, shortId } })
    .json();
  return result;
});

export const lockSlice = createSlice({
  name: "Lock",
  initialState,
  reducers: {
    addPayload: (state, action: PayloadAction<UnlockPayload | null>) => {
      action.payload && state.unlockPayloads.push(action.payload);
    },
    clearPayloads: (state) => {
      state.unlockPayloads = [];
    },
    setUnlockInfo: (state, action: PayloadAction<{ totalLocks: number; shortId: string }>) => {
      state.totalLocks = action.payload.totalLocks;
      state.shortId = action.payload.shortId;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action) => {
      console.log("HYDRATE SUBMARINED CONTENT", action);
    });
    // builder.addCase(verifyPayload.fulfilled, (state, action) => {
    //   action.payload && state.unlockPayload.push(action.payload);
    // });
  },
});

export const { setUnlockInfo, addPayload, clearPayloads } = lockSlice.actions;

export default lockSlice.reducer;
