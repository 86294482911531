import "../styles/globals.css";
import { FC } from "react";
import { Provider } from "react-redux";
import { AppProps } from "next/app";
import { wrapper } from "../store/store";
import ThemeProvider from "../theme/ThemeProvider";
import { useEffect } from "react";
import * as FullStory from "@fullstory/browser";
import { useRouter } from "next/router";
import { rudderInitialize } from "../rudderInitialize";
import { withLDProvider } from "launchdarkly-react-client-sdk";

const MyApp: FC<AppProps> = ({ Component, ...rest }) => {
  const router = useRouter();
  const { store, props } = wrapper.useWrappedStore(rest);

  useEffect(() => {
    FullStory.init(
      { orgId: process.env.NEXT_PUBLIC_FS_ORG_ID as string },
      ({ sessionUrl }) =>
        localStorage.getItem("debug") === "true" && console.log(`Started session: ${sessionUrl}`)
    );
    const handleRouteChange = () => {
      window.rudderanalytics && window.rudderanalytics.page();
    };
    rudderInitialize().then(() => {
      window.rudderanalytics?.page();
    });
    router.events.on("routeChangeStart", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, []);

  return (
    <>
      <Provider store={store}>
        <ThemeProvider>
          <Component {...props.pageProps} />
        </ThemeProvider>
      </Provider>
    </>
  );
};
export default withLDProvider({
  clientSideID: process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID || "",
})(MyApp);

export function reportWebVitals(metric) {
  localStorage.getItem("debug") === "true" && console.log(metric);
}
