import { useAppDispatch, useAppSelector } from "../store/hooks";
import { clearAlert } from "../store/slices/alertSlice";
import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { selectAlert } from "../store/selectors/alertSelectors";
import WarningIcon from "@mui/icons-material/Warning";
import CheckIcon from "@mui/icons-material/Check";

export enum AlertType {
  Warning = "warning",
  Error = "error",
  Info = "info",
}
const ExclamationIconStyled = styled(WarningIcon)`
  color: ${({ theme }) => theme.palette.warning.main};
  height: ${({ theme }) => theme.spacing(3)};
  width: ${({ theme }) => theme.spacing(3)};
  aria-hidden="true";
`;
const CheckIconStyled = styled(CheckIcon)`
  color: ${({ theme }) => theme.palette.success.main};
  height: ${({ theme }) => theme.spacing(3)};
  width: ${({ theme }) => theme.spacing(3)};
`;

const Alert = () => {
  const { type, message, timeout } = useAppSelector(selectAlert);
  const dispatch = useAppDispatch();
  if (timeout) {
    setTimeout(() => {
      dispatch(clearAlert());
    }, timeout);
  }

  if (!message) {
    return null;
  }
  return (
    <Box
      sx={{
        borderLeftWidth: "4px",
        borderColor:
          type === AlertType.Warning || type === AlertType.Error ? "warning.main" : "success.main",
        backgroundColor:
          type === AlertType.Warning || type === AlertType.Error
            ? "rgba(255, 251, 235)"
            : "rgb(240 253 244)",
        top: 0,
        left: 0,
        right: 0,
        position: "fixed",
        zIndex: 9999,
        width: "100%",
        padding: (theme) => theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "auto 1fr",
        }}
      >
        {type === AlertType.Warning || type === AlertType.Error ? (
          <ExclamationIconStyled />
        ) : (
          <CheckIconStyled />
        )}
        <Typography
          sx={{
            fontSize: (theme) => theme.typography.subtitle1.fontSize,
            lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
            marginLeft: (theme) => theme.spacing(1),
            color:
              type === AlertType.Warning || type === AlertType.Error
                ? "rgb(161 98 7)"
                : "rgb(21 128 61)",
          }}
        >
          {message}
        </Typography>
      </Box>
    </Box>
  );
};

export default Alert;
