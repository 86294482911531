import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import { SubmarinedContent } from "../../types/SubmarinedContent";
import { UnlockResult } from "../../types/UnlockResult";
import { tryUnlock } from "./lockSlice";

export interface SubmarinedContentState {
  state: "idle" | "loading" | "succeeded" | "failed";
  content?: SubmarinedContent;
}
const initialState: SubmarinedContentState = {
  state: "idle",
  content: undefined,
};

export const submarinedContentSlice = createSlice({
  name: "submarinedContent",
  initialState,
  reducers: {
    setSubmarinedContent: (state, action: PayloadAction<SubmarinedContent>) => {
      state.content = action.payload;
    },
    clearSubmarinedContent: (state) => {
      state.content = undefined;
    },
    setSubmarinedContentState: (state, action: PayloadAction<SubmarinedContentState["state"]>) => {
      state.state = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action) => {
      console.log("HYDRATE SUBMARINED CONTENT", action);
    });

    builder.addCase(tryUnlock.fulfilled, (state, action) => {
      if (action.payload) {
        if (!isResult(action.payload)) {
          state.content = action.payload;
        }
      }
    });
  },
});

const isResult = (result: UnlockResult | SubmarinedContent | undefined): result is UnlockResult =>
  (result as UnlockResult).status !== undefined;

export const { setSubmarinedContent, clearSubmarinedContent, setSubmarinedContentState } =
  submarinedContentSlice.actions;

export default submarinedContentSlice.reducer;
